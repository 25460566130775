import React from "react";
import Flex from "app/components/Flex";
import { H2, P1 } from "app/components/Typography";
import Button from "app/components/Button";
import PropTypes from "prop-types";
import Modal from "app/components/Modal";
import {
  ModalHeader,
  ModalWrapper,
  ModalBody,
} from "app/components/Modal/components";
import { setLastSeenWeeklyStreakAction } from "modules/progress";
import { useDispatch } from "react-redux";
import Text from "app/components/Text";
import Lottie from "react-lottie-light";
import weeklyStreakAnimationData from "./lottie-weekly-streak.json";

const AchievedWeeklyStreakModal = ({
  isOpen,
  closeModal,
  weeklyStreakCount,
}) => {
  const dispatch = useDispatch();

  return (
    <Modal maxWidth="400px" p={0} isOpen={isOpen}>
      <ModalWrapper maxWidth="400px">
        <Flex flexDirection="column" height="100%" bg="black">
          <ModalHeader p={3}>
            <P1 color="white">New Weekly Streak!</P1>
            <H2 color="white">Congratulations!</H2>
          </ModalHeader>
          <Flex
            bg="bulbaGreen"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            py={4}
            position="relative"
          >
            <Flex position="absolute" top={0} left={0} width="100%">
              <Lottie
                width="100%"
                options={{
                  autoplay: true,
                  loop: true,
                  animationData: weeklyStreakAnimationData,
                }}
              />
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              position="relative"
            >
              <Text
                fontWeight="bold"
                fontSize="200px"
                lineHeight="76%"
                color="black"
              >
                {weeklyStreakCount}
              </Text>
              <H2 mt={3} color="black">
                WEEK STREAK
              </H2>
            </Flex>
          </Flex>
          <ModalBody p={3} bg="black">
            <P1 color="white" mt={1} textAlign="center">
              You met your weekly streak goal!
            </P1>
            <Button
              mt={3}
              onClick={() => {
                dispatch(setLastSeenWeeklyStreakAction(weeklyStreakCount));
                closeModal();
              }}
            >
              Continue Dancing
            </Button>
          </ModalBody>
        </Flex>
      </ModalWrapper>
    </Modal>
  );
};

AchievedWeeklyStreakModal.defaultProps = {
  weeklyStreakCount: 0,
};

AchievedWeeklyStreakModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  weeklyStreakCount: PropTypes.number,
};

export default AchievedWeeklyStreakModal;
